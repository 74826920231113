<template>
  <auth-layout>
    <!-- Forgot password-->
    <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
      <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
        <b-card-title class="mb-1"> Forgot Password? </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll email you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="forgotPassword" #default="{ invalid }">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="forgetPassword"
          >
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button type="submit" variant="primary" class="d-flex align-items-center justify-content-center" block :disabled="invalid || formIsLoading">
              <b-spinner small v-if="formIsLoading" />
              &nbsp;
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-col>
    </b-col>
    <!-- /Forgot password-->
  </auth-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import {
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AuthLayout,
    BCol,
    BLink,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      formIsLoading:false,
      // validation
      required,
      email,
    };
  },
  methods: {
    forgetPassword() {
      this.formIsLoading = true;
      this.$refs.forgotPassword.validate().then(success => {
        if (success) {
          this.$http.post('/forget-password',{
              email:this.userEmail,
            })
            .then(response => {
              this.formIsLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                  text: 'An email has been sent to your email address successfully. It contains the instructions to reset your password',
                },
              });
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      })
    },
  },
};
</script>
